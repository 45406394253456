import React from "react";
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Info from "../components/Info";
import EinzelLeistung from "../components/EinzelLeistung";


const Leistung = () => {
  const data = useStaticQuery(graphql`
  query {
      configJson {
          leistungen {
              head
              id
              kurz
              lang
              url
          }
      }
  }
`)   
const id = 3;
const leistung = data.configJson.leistungen[id];

  return (
  <>    
    <Helmet>
        <title>{leistung.head}</title>
        <meta name='description' content={leistung.short}/>
        <meta name='keywords' content='Physiotherapie, Krankengymnastik, Manuelle Lymphdrainage, Fußreflexzonenmassage, Wärmetherapie, Kältetherapie, Medizinische Massagetherapie, Neurologische Behandlung, Bobath Erwachsene, Faszienbehandlung, Elektrotherapie, Ultraschall, Flossing, Gerätetraining, Hausbesuche, Düren'/>
        <meta property='og:title' content={leistung.head}/>
        <meta property='og:type' content='Website'/>
        <meta property='og:description' content={leistung.short}/>
        <meta property='og:image' content=''/>
        <meta property='og:locale' content='de'/>
          <meta property='og:url' content={`https://www.physiotherapie-winthagen.de/leistung_3`}/>
        <link rel="canonical" href={`https://www.physiotherapie-winthagen.de/leistung_3`}/>
    </Helmet>  
    <Layout>
        <EinzelLeistung id={id}></EinzelLeistung>
        <Info />
    </Layout>
  </>  
  )
}

export default Leistung